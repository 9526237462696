import {Ref} from "vue";


interface FetchFunction<T> {
    (): Promise<T>;
}

export interface IFetch {
    method: "PUT" | "POST" | "GET",
    url: string,
    data?: object | null
}

export interface IFetchEvents {
    onSuccess?: (response?: any) => void,
    onError?: (error?: any) => void,
}

export default function <T>(fetchFunction: FetchFunction<T>, callbacks?: IFetchEvents) {
    const isLoading = ref(false);
    const data: Ref<T | undefined> = ref(undefined)

    const fetch = async () => {
        isLoading.value = true;
        try {
            const response: T = await fetchFunction();
            data.value = response
            if (callbacks?.onSuccess)
                callbacks?.onSuccess(response)
        } catch (error) {
            if (callbacks?.onError)
                callbacks?.onError(error)
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        data,
        fetch
    }
}